
import { Options, Prop, Vue } from "vue-property-decorator";
import IFrameResize from "@/directives/IFrameResize";
import IFrameExternalURL from "@/directives/IFrameExternalURL";

@Options({
  directives: {
    resize: new IFrameResize(),
    externalUrl: new IFrameExternalURL(),
  },
})
export default class Message extends Vue {
  public name = "Message";

  @Prop()
  public body: any;

  public iframeClick() {
    this.$emit("iframe-click");
  }
}
