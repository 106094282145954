import { dispatchEvent } from "@/directives/Helper";

const shrinkContent = (el: HTMLElement, binding: any, vNode: any) => {
  //@ts-ignore
  const iframeBody = el.contentWindow.document.body;

  //@ts-ignore
  const height = iframeBody.offsetHeight;

  iframeBody.style.overflow = "hidden";
  iframeBody.style.margin = 0;
  iframeBody.style.overflowWrap = "break-word";

  if (height > 150) {
    el.style.height = `${height}px`;
  }

  iframeBody.onclick = () => {
    dispatchEvent(el, binding, vNode, "iframe-click");
  };
};

export default class IFrameResize {
  public beforeMount(el: HTMLElement, binding: any, vNode: any) {
    el.addEventListener("load", () => shrinkContent(el, binding, vNode));
  }

  public unmounted(el: HTMLElement, binding: any, vNode: any) {
    el.removeEventListener("load", () => shrinkContent(el, binding, vNode));
  }
}
